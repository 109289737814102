.circle:first-child {
    height: 55px;
    left: -13%;
    top: 2%;
    width: 55px
}

.circle:first-child>.path {
    height: 42.5px
}

.circle:first-child>.path>.dot {
    background-color: #49cb15
}

.circle:nth-child(2) {
    height: 37px;
    left: -14%;
    top: 8%;
    width: 37px
}

.circle:nth-child(2)>.path {
    height: 33.5px
}

.circle:nth-child(2)>.path>.dot {
    background-color: #1595cb
}

.circle:nth-child(3) {
    height: 52px;
    left: -4%;
    top: -8%;
    width: 52px
}

.circle:nth-child(3)>.path {
    height: 41px
}

.circle:nth-child(3)>.path>.dot {
    background-color: #b0cb15
}

.circle:nth-child(4) {
    height: 47px;
    left: -9%;
    top: -7%;
    width: 47px
}

.circle:nth-child(4)>.path {
    height: 38.5px
}

.circle:nth-child(4)>.path>.dot {
    background-color: #79cb15
}

.circle:nth-child(5) {
    height: 48px;
    left: 6%;
    top: 12%;
    width: 48px
}

.circle:nth-child(5)>.path {
    height: 39px
}

.circle:nth-child(5)>.path>.dot {
    background-color: #cb7015
}

.circle:nth-child(6) {
    height: 57px;
    left: -5%;
    width: 57px
}

.circle:nth-child(6)>.path {
    height: 43.5px
}

.circle:nth-child(6)>.path>.dot {
    background-color: #15cbc2
}

.circle:nth-child(7) {
    height: 41px;
    left: -7%;
    top: 14%;
    width: 41px
}

.circle:nth-child(7)>.path {
    height: 35.5px
}

.circle:nth-child(7)>.path>.dot {
    background-color: #cb3a15
}

.circle:nth-child(8) {
    height: 33px;
    left: -10%;
    top: 4%;
    width: 33px
}

.circle:nth-child(8)>.path {
    height: 31.5px
}

.circle:nth-child(8)>.path>.dot {
    background-color: #15cb40
}

.circle:nth-child(9) {
    height: 50px;
    left: 15%;
    top: -1%;
    width: 50px
}

.circle:nth-child(9)>.path {
    height: 40px
}

.circle:nth-child(9)>.path>.dot {
    background-color: #15cb3d
}

.circle:nth-child(10) {
    height: 53px;
    left: -1%;
    top: -8%;
    width: 53px
}

.circle:nth-child(10)>.path {
    height: 41.5px
}

.circle:nth-child(10)>.path>.dot {
    background-color: #159bcb
}

:root {
    --w: 255,255,255;
    --w1: 240,246,253;
    --g: 78,224,135;
    --o: 246,166,35;
    --r: 255,118,109;
    --r1: 248,68,71
}

@keyframes animate-bet-win {
    0% {
        background-color: #8bbb4c;
        border-color: #8bbb4c;
        opacity: 0;
        transform: scale(.1) translateY(330px)
    }

    20% {
        opacity: .5;
        transform: scale(.5)
    }

    40% {
        opacity: .7;
        transform: scale(.65)
    }

    50% {
        opacity: 1;
        transform: scale(.8)
    }

    75% {
        transform: scale(1.06)
    }

    90% {
        transform: scale(.96) translateY(0)
    }

    to {
        background-color: #30373f;
        border-color: #3a434b;
        transform: scale(1) translateY(0)
    }
}

@keyframes animate-bet-lose {
    0% {
        background-color: #f84447;
        border-color: #f84447;
        opacity: 0;
        transform: scale(.1) translateY(330px)
    }

    20% {
        opacity: .5;
        transform: scale(.4)
    }

    40% {
        opacity: .7;
        transform: scale(.6)
    }

    50% {
        opacity: 1;
        transform: scale(.8)
    }

    75% {
        transform: scale(1.06)
    }

    90% {
        transform: scale(.96) translateY(0)
    }

    to {
        background-color: #30373f;
        border-color: #3a434b;
        transform: scale(1) translateY(0)
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

@keyframes eggshaking1 {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(3deg)
    }

    20% {
        transform: rotate(0deg)
    }

    30% {
        transform: rotate(3deg)
    }

    40% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(3deg)
    }

    60% {
        transform: rotate(0deg)
    }

    70% {
        transform: rotate(3deg)
    }

    80% {
        transform: rotate(0deg)
    }

    90% {
        transform: rotate(-3deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@keyframes eggshaking2 {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(-4deg)
    }

    20% {
        transform: rotate(0deg)
    }

    30% {
        transform: rotate(-4deg)
    }

    40% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-4deg)
    }

    60% {
        transform: rotate(0deg)
    }

    70% {
        transform: rotate(-4deg)
    }

    80% {
        transform: rotate(0deg)
    }

    90% {
        transform: rotate(4deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@keyframes eggshaking3 {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(5deg)
    }

    20% {
        transform: rotate(0deg)
    }

    30% {
        transform: rotate(5deg)
    }

    40% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(5deg)
    }

    60% {
        transform: rotate(0deg)
    }

    70% {
        transform: rotate(5deg)
    }

    80% {
        transform: rotate(0deg)
    }

    90% {
        transform: rotate(-5deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@keyframes eggshaking4 {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(-4deg)
    }

    20% {
        transform: rotate(0deg)
    }

    30% {
        transform: rotate(-4deg)
    }

    40% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-4deg)
    }

    60% {
        transform: rotate(0deg)
    }

    70% {
        transform: rotate(-4deg)
    }

    80% {
        transform: rotate(0deg)
    }

    90% {
        transform: rotate(4deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@keyframes bounce {
    0%,20%,50%,80%,to {
        transform: translateY(0)
    }

    40% {
        transform: translateY(-6px)
    }

    60% {
        transform: translateY(-2px)
    }
}

@keyframes scale-rotate-delay {
    20% {
        transform: scale(1) rotate(0deg)
    }

    22% {
        transform: scale(2) rotate(-40deg)
    }

    24% {
        transform: scale(1) rotate(0deg)
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.2)
    }

    30% {
        transform: scale(1)
    }

    45% {
        transform: scale(1.2)
    }

    60% {
        transform: scale(1)
    }

    to {
        transform: scale(1)
    }
}

@keyframes zoom-in {
    0% {
        opacity: 0
    }

    20% {
        opacity: .5
    }

    50% {
        opacity: .8
    }

    to {
        opacity: 1
    }
}

@keyframes splash-fade {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes star-fade {
    0% {
        opacity: 0;
        transform: scale(0)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes chat-panel {
    0% {
        opacity: 1;
        transform: rotateY(0deg)
    }

    25% {
        opacity: .1
    }

    50% {
        opacity: 0;
        transform: rotateY(180deg)
    }

    75% {
        opacity: .1
    }

    to {
        opacity: 1;
        transform: rotateY(0deg)
    }
}

@keyframes chat-panel-delay {
    0% {
        opacity: 0;
        transform: rotateY(180deg)
    }

    25% {
        opacity: 0
    }

    50% {
        opacity: 1;
        transform: rotateY(0deg)
    }

    75% {
        opacity: 0
    }

    to {
        opacity: 0;
        transform: rotateY(180deg)
    }
}

@keyframes birthday-bonus-coin {
    0% {
        opacity: 0;
        transform: rotateY(0deg)
    }

    40% {
        opacity: .7;
        transform: rotateY(180deg) translateY(-15px)
    }

    80%,to {
        opacity: 1;
        transform: rotateY(0deg)
    }
}

@keyframes parabala {
    35% {
        transform: rotate(170deg)
    }

    55% {
        opacity: .55
    }

    75% {
        opacity: 0
    }

    to {
        opacity: 0;
        transform: rotate(179deg) translateY(-60vw) translateX(-10vw)
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(1);
        z-index: 5
    }

    50% {
        opacity: 1;
        transform: scale(1.5)
    }

    70% {
        transform: scale(1);
        z-index: 5
    }
}

@keyframes run {
    0% {
        opacity: 0;
        transform: translate3d(100%,0,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.circle {
    animation: scale 1s;
    border-radius: 50%;
    position: absolute;
    transform: scale(0)
}

.circle:first-child {
    animation-delay: .3s;
    height: 40px;
    left: -10%;
    top: -1%;
    width: 40px
}

.circle:first-child>.path {
    height: 35px
}

.circle:first-child>.path>.dot {
    animation-delay: .3s;
    background-color: #7ccb15
}

.circle:nth-child(2) {
    animation-delay: .6s;
    height: 35px;
    left: -12%;
    top: 15%;
    width: 35px
}

.circle:nth-child(2)>.path {
    height: 32.5px
}

.circle:nth-child(2)>.path>.dot {
    animation-delay: .6s;
    background-color: #cb8215
}

.circle:nth-child(3) {
    animation-delay: .9s;
    height: 34px;
    left: 3%;
    top: 4%;
    width: 34px
}

.circle:nth-child(3)>.path {
    height: 32px
}

.circle:nth-child(3)>.path>.dot {
    animation-delay: .9s;
    background-color: #9ecb15
}

.circle:nth-child(4) {
    animation-delay: 1.2s;
    height: 48px;
    left: -6%;
    top: -4%;
    width: 48px
}

.circle:nth-child(4)>.path {
    height: 39px
}

.circle:nth-child(4)>.path>.dot {
    animation-delay: 1.2s;
    background-color: #c5cb15
}

.circle:nth-child(5) {
    animation-delay: 1.5s;
    height: 34px;
    left: 7%;
    top: -2%;
    width: 34px
}

.circle:nth-child(5)>.path {
    height: 32px
}

.circle:nth-child(5)>.path>.dot {
    animation-delay: 1.5s;
    background-color: #cb2e15
}

.circle:nth-child(6) {
    animation-delay: 1.8s;
    height: 53px;
    left: 1%;
    top: -6%;
    width: 53px
}

.circle:nth-child(6)>.path {
    height: 41.5px
}

.circle:nth-child(6)>.path>.dot {
    animation-delay: 1.8s;
    background-color: #cbb315
}

.circle:nth-child(7) {
    animation-delay: 2.1s;
    height: 58px;
    left: 12%;
    top: -2%;
    width: 58px
}

.circle:nth-child(7)>.path {
    height: 44px
}

.circle:nth-child(7)>.path>.dot {
    animation-delay: 2.1s;
    background-color: #cba715
}

.circle:nth-child(8) {
    animation-delay: 2.4s;
    height: 38px;
    left: 15%;
    top: -4%;
    width: 38px
}

.circle:nth-child(8)>.path {
    height: 34px
}

.circle:nth-child(8)>.path>.dot {
    animation-delay: 2.4s;
    background-color: #bccb15
}

.circle:nth-child(9) {
    animation-delay: 2.7s;
    height: 55px;
    left: 14%;
    top: -10%;
    width: 55px
}

.circle:nth-child(9)>.path {
    height: 42.5px
}

.circle:nth-child(9)>.path>.dot {
    animation-delay: 2.7s;
    background-color: #cb8515
}

.circle:nth-child(10) {
    animation-delay: 3s;
    height: 56px;
    left: 1%;
    top: 14%;
    width: 56px
}

.circle:nth-child(10)>.path {
    height: 43px
}

.circle:nth-child(10)>.path>.dot {
    animation-delay: 3s;
    background-color: #cb9215
}

.circle .path {
    left: calc(50% - 1px);
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 2px
}

.circle .path:first-child {
    transform: rotate(0deg)
}

.circle .path:nth-child(2) {
    transform: rotate(20deg)
}

.circle .path:nth-child(3) {
    transform: rotate(40deg)
}

.circle .path:nth-child(4) {
    transform: rotate(60deg)
}

.circle .path:nth-child(5) {
    transform: rotate(80deg)
}

.circle .path:nth-child(6) {
    transform: rotate(100deg)
}

.circle .path:nth-child(7) {
    transform: rotate(120deg)
}

.circle .path:nth-child(8) {
    transform: rotate(140deg)
}

.circle .path:nth-child(9) {
    transform: rotate(160deg)
}

.circle .path:nth-child(10) {
    transform: rotate(180deg)
}

.circle .path:nth-child(11) {
    transform: rotate(200deg)
}

.circle .path:nth-child(12) {
    transform: rotate(220deg)
}

.circle .path:nth-child(13) {
    transform: rotate(240deg)
}

.circle .path:nth-child(14) {
    transform: rotate(260deg)
}

.circle .path:nth-child(15) {
    transform: rotate(280deg)
}

.circle .path:nth-child(16) {
    transform: rotate(300deg)
}

.circle .path:nth-child(17) {
    transform: rotate(320deg)
}

.circle .path:nth-child(18) {
    transform: rotate(340deg)
}

.circle .path .dot {
    animation: dotScale 1s infinite;
    border-radius: 20px;
    height: 30px;
    left: -1px;
    position: absolute;
    top: -5px;
    transform-origin: 50% 0;
    width: 4px
}

@keyframes dotScale {
    to {
        height: 0
    }
}

@keyframes scale {
    to {
        transform: scale(1)
    }
}

@keyframes pulse {
    75% {
        transform: scale(1)
    }

    80% {
        transform: scale(1.5)
    }
}

@keyframes sockShacking {
    0% {
        transform: rotate(0deg)
    }

    10% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(0deg)
    }

    35% {
        transform: rotate(-5deg)
    }

    45% {
        transform: rotate(2deg)
    }

    55% {
        transform: rotate(0deg)
    }

    65% {
        transform: rotate(0deg)
    }

    75% {
        transform: rotate(-5deg)
    }

    90% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(0deg)
    }
}

@keyframes slideUp {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    20% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(-200%)
    }
}

@keyframes snowfall {
    0% {
        opacity: 1;
        transform: translateZ(0) skew(5deg) rotateX(0) rotateY(0)
    }

    65% {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translate3d(-120px,100vh,0) skew(-5deg) rotateX(50deg) rotateY(50deg)
    }
}

@keyframes secondSnowfall {
    0% {
        opacity: 0;
        transform: translateZ(0) skew(5deg) rotateX(0) rotateY(0)
    }

    1% {
        opacity: 1;
        transform: translateZ(0) skew(5deg) rotateX(0) rotateY(0);
        z-index: 100
    }

    65% {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translate3d(-120px,100vh,0) skew(-5deg) rotateX(50deg) rotateY(50deg)
    }
}

@keyframes spin {
    to {
        transform: rotate(-1turn)
    }
}

@keyframes win {
    0% {
        transform: scaleX(1)
    }

    50% {
        transform: scale3d(1.05,1.05,1.05)
    }

    to {
        transform: scaleX(1)
    }
}

@keyframes lose {
    0% {
        transform: scale3d(.9,.9,.9)
    }

    10%,20% {
        transform: scale3d(.8,.8,.8) rotate(-3deg)
    }

    30%,50%,70%,90% {
        transform: scaleX(1) rotate(3deg)
    }

    40%,60%,80% {
        transform: scaleX(1) rotate(-3deg)
    }

    to {
        transform: scale3d(.9,.9,.9)
    }
}

@keyframes theshine {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 0,160;
        stroke-dashoffset: -120
    }

    50% {
        stroke-dasharray: 160,0;
        stroke-dashoffset: -120
    }

    to {
        stroke-dasharray: 0,160;
        stroke-dashoffset: -280
    }
}

@keyframes thelightning-variation {
    0% {
        transform: translateZ(0) rotate(0deg)
    }

    49% {
        transform: translateZ(0) rotate(0deg)
    }

    50% {
        transform: translateZ(0) rotate(90deg)
    }

    99% {
        transform: translateZ(0) rotate(90deg)
    }

    to {
        transform: translateZ(0) rotate(270deg)
    }
}

@keyframes thelightning-opacity {
    0% {
        opacity: 0
    }

    25% {
        opacity: 0
    }

    26% {
        opacity: 1
    }

    27% {
        opacity: 1
    }

    28% {
        opacity: 1
    }

    29% {
        opacity: 0
    }

    35% {
        opacity: 0
    }

    36% {
        opacity: 1
    }

    37% {
        opacity: 1
    }

    38% {
        opacity: 1
    }

    49% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    51% {
        opacity: 0
    }

    55% {
        opacity: 1
    }

    56% {
        opacity: 0
    }

    60% {
        opacity: 1
    }

    61% {
        opacity: 0
    }

    70% {
        opacity: 0
    }

    71% {
        opacity: 1
    }

    73% {
        opacity: 1
    }

    74% {
        opacity: 0
    }

    77% {
        opacity: 0
    }

    78% {
        opacity: 1
    }

    81% {
        opacity: 1
    }

    82% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

@keyframes offline-rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

@keyframes game-pulse {
    0% {
        box-shadow: none
    }

    90% {
        box-shadow: inset 0 0 15px #8bbb4c
    }
}

@keyframes game-pulse-demo {
    0% {
        box-shadow: none
    }

    90% {
        box-shadow: inset 0 0 15px #3f88c2
    }
}

@keyframes sniper-race-pulse {
    0% {
        box-shadow: none
    }

    90% {
        box-shadow: inset 0 0 15px #8c5679
    }
}

@keyframes loaderBlock {
    0% {
        transform: translateZ(0)
    }

    15% {
        transform: translateZ(0)
    }

    20% {
        transform: translate3d(0,95%,0)
    }

    35% {
        transform: translate3d(0,95%,0)
    }

    50% {
        transform: translate3d(95%,95%,0)
    }

    65% {
        transform: translate3d(95%,95%,0)
    }

    75% {
        transform: translate3d(95%,0,0)
    }

    90% {
        transform: translate3d(95%,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

@keyframes rise {
    0% {
        opacity: 0;
        transform: translateY(0) scale(1)
    }

    25% {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translateY(-10em) scale(0)
    }
}

@keyframes affiliate-button {
    0%,10% {
        transform: translateX(-10em) skewX(-45deg)
    }

    90%,to {
        transform: translateX(10em) skewX(-45deg)
    }
}

@keyframes shift {
    0% {
        left: 0
    }

    to {
        left: 100%
    }
}

@keyframes move-cloud-2 {
    0% {
        -webkit-transform: translate3d(-200px,2000px,-100px)
    }

    to {
        -webkit-transform: translate3d(2000px,-200px,100px)
    }
}

@keyframes move-cloud-1 {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-200px,100px,700px)
    }

    to {
        opacity: .5;
        -webkit-transform: translate3d(200px,-100px,-700px)
    }
}

@keyframes move-cloud-3 {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(1500px,0,-300px)
    }

    to {
        opacity: .5;
        -webkit-transform: translate3d(-1500px,0,300px)
    }
}

@keyframes move-cloud-4 {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-100px,0,0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0,300px,500px)
    }
}

@keyframes move-cloud-5 {
    0% {
        -webkit-transform: translate3d(2000px,140px,500px)
    }

    to {
        -webkit-transform: translate3d(-2000px,-140px,-500px)
    }
}

@keyframes move-cloud-6 {
    0% {
        -webkit-transform: translate3d(-2000px,350px,-500px)
    }

    to {
        -webkit-transform: translate3d(2000px,-350px,500px)
    }
}

@keyframes move-cloud-7 {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(0,-300px,-500px)
    }

    to {
        opacity: .5;
        -webkit-transform: translate3d(0,300px,500px)
    }
}

@keyframes betLimitsNotification {
    0% {
        opacity: 0
    }

    8% {
        opacity: 1
    }

    73% {
        opacity: 1;
        transform: translateY(0)
    }

    95% {
        opacity: 0
    }

    to {
        opacity: 0;
        transform: translateY(-100px)
    }
}

@keyframes blink {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes bang {
    to {
        box-shadow: 31px 39.66666667px #00ff6f,-81px -204.33333333px #9f0,-111px -100.33333333px #00f7ff,16px 27.66666667px #00ff1e,-26px -181.33333333px #ff2f00,69px -119.33333333px #e6ff00,31px 9.66666667px #0f0,103px -193.33333333px #0f6,-105px 6.66666667px #ff3c00,-42px -53.33333333px #ff00a2,33px -188.33333333px #fc0,112px -166.33333333px #f200ff,-95px -179.33333333px #0af,55px -49.33333333px #51ff00,-60px -111.33333333px #6200ff,-71px -164.33333333px #f0d,-6px -113.33333333px #00ff37,-72px -94.33333333px #06f,-2px -197.33333333px #00ff2f,-112px -19.33333333px #6f0,70px -31.33333333px #3700ff,-27px -68.33333333px #e0f,-68px -77.33333333px #ff9500,-7px -61.33333333px #00ff09,-96px -126.33333333px #04f,-91px -119.33333333px #0fb,31px -107.33333333px #e0f,-13px -166.33333333px #90f,-114px -108.33333333px #ff0080,32px -15.33333333px #f02,115px -90.33333333px #ff001e
    }
}

@keyframes gravity {
    to {
        opacity: 0;
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px)
    }
}

@keyframes position {
    0%,19.9% {
        margin-left: 40%;
        margin-top: 10%
    }

    20%,39.9% {
        margin-left: 30%;
        margin-top: 40%
    }

    40%,59.9% {
        margin-left: 70%;
        margin-top: 20%
    }

    60%,79.9% {
        margin-left: 20%;
        margin-top: 30%
    }

    80%,99.9% {
        margin-left: 80%;
        margin-top: 30%
    }
}

@keyframes position2 {
    0%,19.9% {
        margin-left: 80%;
        margin-top: 60%
    }

    20%,39.9% {
        margin-left: 70%;
        margin-top: 20%
    }

    40%,59.9% {
        margin-left: 20%;
        margin-top: 30%
    }

    60%,79.9% {
        margin-left: 80%;
        margin-top: 30%
    }

    80%,99.9% {
        margin-left: 40%;
        margin-top: 10%
    }
}

@keyframes position3 {
    0%,19.9% {
        margin-left: 10%;
        margin-top: 70%
    }

    20%,39.9% {
        margin-left: 70%;
        margin-top: 10%
    }

    40%,59.9% {
        margin-left: 20%;
        margin-top: 30%
    }

    60%,79.9% {
        margin-left: 80%;
        margin-top: 30%
    }

    80%,99.9% {
        margin-left: 40%;
        margin-top: 10%
    }
}

@keyframes moveCloudTwo {
    0% {
        right: 0
    }

    1% {
        opacity: .5;
        right: 1%
    }

    99% {
        opacity: .5;
        right: 99%
    }

    to {
        opacity: 0;
        right: 100%
    }
}

@keyframes moveCloud {
    0% {
        left: 0
    }

    1% {
        left: 1%;
        opacity: .5
    }

    99% {
        left: 99%;
        opacity: .5
    }

    to {
        left: 100%;
        opacity: 0
    }
}

@keyframes moveclouds {
    0% {
        left: -85px
    }

    10% {
        opacity: 1
    }

    to {
        left: -200px;
        opacity: 0;
        top: 80px
    }
}

@keyframes movecloudsRight {
    0% {
        right: -130px;
        top: 25px
    }

    10% {
        opacity: 1
    }

    to {
        right: -150px;
        top: 38px
    }
}

@keyframes movecloudsTop {
    0% {
        left: 100px;
        top: 112px
    }

    10% {
        opacity: 1
    }

    to {
        left: 80px;
        opacity: 0;
        top: 180px
    }
}

@keyframes moveSun {
    30% {
        bottom: -28%;
        opacity: 1
    }

    to {
        bottom: -10%;
        opacity: 0;
        transform: rotate(1turn)
    }
}

@keyframes moveHillLeft {
    30% {
        opacity: 1
    }

    to {
        bottom: -80px;
        left: -45px;
        opacity: 0
    }
}

@keyframes moveHillRight {
    10% {
        opacity: 1
    }

    to {
        bottom: -32px;
        opacity: 0;
        right: -75px
    }
}

@keyframes showImage {
    0%,99% {
        max-width: 0;
        opacity: 0
    }

    to {
        max-width: 204px;
        opacity: 1
    }
}

@keyframes navigation-category-block {
    0% {
        opacity: 0;
        transform: scale(0)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

p {
    margin: 0 0 1.5rem
}

.tab-content p {
    margin: 0 0 15px
}

.page-block_cms a {
    text-decoration: underline
}

.page-block_cms .link_custom {
    text-decoration: none
}

h1,h2,h3,h4 {
    font-weight: 500;
    margin: 0 0 15px
}

h1 {
    font-size: 25px;
    margin: 0 0 30px
}

@media (max-width: 768px) {
    h1 {
        margin:0 0 20px
    }
}

h2 {
    font-size: 20px
}

h3 {
    font-size: 18px;
    margin-bottom: 5px
}

.tab-content h3,h3.title {
    margin-bottom: 15px
}

h3.title {
    text-transform: none
}

.h4,h4 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px
}

.h4_custom,h4_custom {
    font-size: 14px;
    text-transform: uppercase
}

ol {
    list-style: decimal inside;
    margin: 0;
    padding: 0 0 15px
}

ul {
    list-style: inside;
    margin: 0 0 15px;
    padding: 0
}

.h2_custom {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase!important
}

.title-default {
    margin: 0
}

.font-bold {
    font-weight: 600
}

.size-small {
    font-size: 13px
}

html {
    height: 100%
}

#react-errors-layout {
    bottom: 0;
    display: none;
    left: 0;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999
}

body.error-scroll-hidden {
    overflow: hidden!important;
    position: fixed
}

#react-errors-layout,body.error-page {
    background-color: #2a3741;
    color: #8a99a5;
    font-family: Open Sans,sans-serif;
    font-size: 14px;
    height: 100%;
    line-height: 18px;
    margin: 0;
    padding: 0;
    width: 100%
}

#react-errors-layout a,body.error-page a {
    color: #f6a623
}

#react-errors-layout a:hover,body.error-page a:hover {
    color: #f59e0f
}

#react-errors-layout .title,body.error-page .title {
    color: #fff
}

#react-errors-layout .image img,body.error-page .image img {
    display: block
}

#react-errors-layout .logo,body.error-page .logo {
    align-items: center;
    display: flex;
    font-size: 25px;
    font-weight: 700;
    line-height: 37px;
    text-decoration: none
}

#react-errors-layout .logo img,body.error-page .logo img {
    height: 37px;
    margin: 0 10px 0 0;
    width: 37px
}

#react-errors-layout .logo span,body.error-page .logo span {
    color: #fff
}

#react-errors-layout .container,body.error-page .container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 968px;
    padding: 2rem 0 1rem;
    text-align: center
}

#react-errors-layout .container__content,body.error-page .container__content {
    padding: 0 0 3.5rem
}

#react-errors-layout .container .title,body.error-page .container .title {
    font-size: 25px;
    line-height: 28px;
    margin: 0 0 10px;
    min-height: 28px;
    position: relative;
    text-transform: capitalize
}

#react-errors-layout .container .title span,body.error-page .container .title span {
    display: block;
    left: 50%;
    margin: 0 0 0 -155px;
    position: absolute;
    text-align: left;
    top: 0;
    width: 310px
}

#react-errors-layout .container .image,body.error-page .container .image {
    height: auto;
    margin: 0 auto 25px;
    width: 290px
}

#react-errors-layout .container .image a,body.error-page .container .image a {
    display: block
}

#react-errors-layout .container .image img,body.error-page .container .image img {
    display: block;
    height: 100%;
    width: 100%
}

#react-errors-layout .container__footer,body.error-page .container__footer {
    color: #5c6b78;
    margin-bottom: 30px
}

#react-errors-layout.maintenance-page,body.error-page.maintenance-page {
    background: #2c333a url(https://duckdice.io/assets/images/pattern-dark.png)
}

#react-errors-layout.maintenance-page__container,body.error-page.maintenance-page__container {
    max-width: 500px
}

#react-errors-layout.maintenance-page .image,body.error-page.maintenance-page .image {
    margin-bottom: 15px;
    max-width: 10.5rem;
    width: 10.5rem
}

@media (max-width: 1023px) {
    #react-errors-layout .container,body.error-page .container {
        max-width:100%;
        padding: 1rem
    }
}

@media (max-width: 480px) {
    #react-errors-layout .container,body.error-page .container {
        padding:1rem
    }

    #react-errors-layout .container .image,body.error-page .container .image {
        width: 200px
    }

    #react-errors-layout .container .title,body.error-page .container .title {
        font-size: 23px
    }

    #react-errors-layout .container .title span,body.error-page .container .title span {
        margin: 0 0 0 -143px;
        width: 286px
    }
}
